import React from "react"
import Layout from "../../components/layout"
import "../../styles/branches.css"
import { getWords } from "../../helper-functions"
import SEO from "../../components/seo"
import { hostels } from "../../data/campus"
import HostelCard from "../../components/hostelCard"
function Hostels() {
  return (
    <div>
      <Layout>
        <SEO
          title="Hostels"
          description="Bhawans! That is how we define our dwelling places on the sprawling IIT campus. These bhawans are our legacy and they remind of us the glory this institute has had and of the renaissance it has witnessed. Our homes away from home are as modern as they can be. More importantly, though have spawned the cultures that make the campus an unforgettable experience. It's here that midnight cricket tournaments, early morning bakar sessions, community computer warfare and so many other things find expression."
          image={require("../../images/home/Hostels.jpg")}
        />
        <h2 className="dark-color-head">Hostels</h2>
        <p className="normal-para">
          Bhawans! That is how we define our dwelling places on the sprawling
          IIT campus. These bhawans are our legacy and they remind of us the
          glory this institute has had and of the renaissance it has witnessed.
          <br />
          Our homes away from home are as modern as they can be. More
          importantly, though have spawned the cultures that make the campus an
          unforgettable experience. It's here that midnight cricket tournaments,
          early morning bakar sessions, community computer warfare and so many
          other things find expression.
          <br />
          Each hostel has a state of art Cyber Cafe, managed by students. All
          hostels are now fully Wifi connected, meaning that the Internet
          permeates the space we live in. And who could imagine life without
          midnight snacks at the Bhawan Canteens, especially after an intense
          bakar or gaming session? And all this, in peaceful, clean, aesthetic
          environs, the likes of which very few places in the world can boast.
          <br />
        </p>
        <div className="branches-card">
          {hostels.map(hostel => (
            <div>
              <a href={hostel.site} target="_blank" rel="noopener noreferrer">
                <HostelCard
                  carddata={{
                    title: hostel.name,
                    content: `${getWords(hostel.content, 13)}...`,
                    link: hostel.site,
                    image: hostel.image,
                  }}
                />
              </a>
            </div>
          ))}
        </div>
      </Layout>
    </div>
  )
}

export default Hostels
