import React from "react"
import { Card } from "semantic-ui-react"
import "../styles/card1.css"
import { Image } from "semantic-ui-react"
import { Icon } from "semantic-ui-react"

const HostelCard = props => (
  <Card className={"card1"} raised={false}>
    <Image src={props.carddata.image} fluid />
    <Card.Content>
      <Card.Header className="card1-header">
        <div className="hostellink">
          {props.carddata.title}
          <Icon fitted name="external alternate" />
        </div>
      </Card.Header>
      <Card.Description className="card1-desc">
        {props.carddata.content}
      </Card.Description>
    </Card.Content>
  </Card>
)

export default HostelCard
